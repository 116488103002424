import { Container, Heading } from "@chakra-ui/react";
import React from "react";
import PageLayout from "../components/layout/PageLayout";

const TermsOfService = () => {
  return (
    <PageLayout >
      <Container mt="3" justifyContent="left">      
      <Heading>
        TÉRMINOS Y CONDICIONES SOBRE aplicación en línea de RESIDENCIAL LA HACIENDA
      </Heading>

      <h3>  
      Introducción
      </h3>

      <p>
      A continuación se presentan los Términos de acceso y uso de la aplicación La Hacienda Online. Al usar la aplicación La Hacienda Online, usted afirma que es mayor de edad para ingresar en estos Términos de uso o, si no lo es, que ha obtenido el consentimiento de los padres o tutores. Si viola o no está de acuerdo con estos Términos de uso, entonces su acceso y uso de la aplicación La Hacienda Online no está autorizado.
      </p>
      <h3>
      TÉRMINOS DEFINIDOS:
      </h3>
        <p>
        En estos Términos de uso:
        </p>

      <p>
        ·        Al decir Residencial La Hacienda, nos referimos al dominio o sitio de internet www.reslahacienda.com. También nos referimos a Residencial La Hacienda como «nosotros», «nos» y «nuestro».
        </p>
      <p>
      ·    Cuando decimos «usted» o «su» nos referimos a cualquier usuario (como usted) del dominio o sitio de internet www.reslahacienda.com
      </p>
      <p>

      ·     Cuando decimos «Términos de uso», nos referimos a estos Términos de uso y todos los demás términos y políticas publicados por Residencial La Hacienda en su aplicación en línea (y cualquier actualización a estos Términos de uso y políticas).
      </p>
      <p>

      ·          Algunos otros términos clave utilizados en estos Términos de uso:
      </p>
      <p>

      ·    Cuando decimos «Contenido», nos referimos a información de comunicaciones, reseñas, mensajes, comentarios, sugerencias, preguntas y otra información, datos, contenido y materiales (incluidos encabezados de página, imágenes, texto, ilustraciones, formatos, logotipos, hashtags, diseños, iconos, fotografías, programas de software, videoclips o descargas, materiales escritos y otros).
      </p>
      <p>
      ·        Cuando decimos «Ideas», nos referimos a ideas, conceptos, comentarios y conocimientos que usted pone a disposición en relación a la aplicación del dominio o sitio de internet www.reslahacienda.com
      </p>
      <p>
      ·    Cuando decimos «poner a disposición», nos referimos a publicar, transmitir, mostrar, proporcionar o de otro modo enviar o poner a disposición de las plataformas digitales que redirijan y permitan la interacción con la aplicación en línea a través de las herramientas que ofrecen dichas plataformas de redes sociales.
      </p>
      <h3>
      ACTUALIZACIONES 
      </h3>
      <p>
      Podemos actualizar estos Términos de uso de vez en cuando notificándole dichos cambios por cualquier medio razonable, incluso mediante la publicación de los Términos de uso revisados ​​a través de nuestras redes sociales o aplicación en línea. Cualquiera de estos cambios no se aplicará a ninguna disputa entre usted y nosotros que surja antes de la fecha en que publicamos los Términos de uso revisados ​​que incorporan dichos cambios o que de otra manera le notificamos dichos cambios. Al continuar usando o accediendo a la aplicación La Hacienda Online después de que publiquemos cualquier cambio, usted acepta los Términos de uso actualizados. 
      </p>
      <p>
      Se advierte a los Usuarios que la información de la aplicación en línea puede contener inexactitudes, está incompleta o desactualizada. Por ello, Residencial La Hacienda se reserva el derecho de corregir cualquier error, omisión, cambiar o actualizar la misma en cualquier momento sin previo aviso.
      </p>
      <p>
      2. Su uso de la aplicación La Hacienda Online
      </p>
      <p>
      Usted certifica que el contenido que proporciona en o a través de la aplicación en línea es exacto y que la información que proporciona en o a través de la aplicación en línea está completa. Usted es el único responsable de mantener la confidencialidad y seguridad de su cuenta, incluidos el nombre de usuario, correo y la contraseña. Residencial La Hacienda no se hace responsable de las pérdidas derivadas del uso no autorizado de su cuenta. Usted acepta que Residencial La Hacienda no tiene ninguna responsabilidad si pierde o comparte el acceso a su dispositivo. Cualquier acuerdo entre usted y el emisor de su tarjeta de crédito, tarjeta de débito u otra forma de pago continuará rigiendo su uso de dicho método de pago en la aplicación La Hacienda Online Usted acepta que Residencial La Hacienda no es parte de dicho acuerdo, ni es responsable del contenido, la precisión o la falta de disponibilidad de ningún método utilizado para el pago. Su cuenta puede ser restringida o cancelada por cualquier motivo, a nuestro exclusivo criterio. Salvo que la ley disponga lo contrario, en cualquier momento sin previo aviso, podemos (1) cambiar, restringir el acceso, suspender o descontinuar la aplicación en línea o cualquier parte del mismo, y (2) cobrar, modificar o renunciar a las tarifas requeridas para usar cualquier servicio, funcionalidad u otro contenido disponible a través de la aplicación La Hacienda Online o cualquier parte de esta.
      </p>
      <p>
      3. Material Digital disponible en la aplicación La Hacienda Online
      </p>
      <p>
      Residencial La Hacienda puede poner a disposición varios Materiales. Los Materiales son solo para fines educativos e informativos, y de vez en cuando pueden aparecer errores. Antes de actuar confiando en cualquier Material, debe confirmar cualquier hecho que sea importante para su decisión. Las Entidades de Residencial La Hacienda no garantizan la confiabilidad, precisión, oportunidad, utilidad o integridad de ningún Material. Si encuentra un error o nota algo que no se ve del todo bien en la aplicación en línea, le agradeceríamos que nos lo comunique contactando con nosotros al correo soporte@reslahacienda.com.
      </p>

      <p>
      Residencial La Hacienda NO ASUME NINGUNA RESPONSABILIDAD POR NINGUNA CONSECUENCIA RELACIONADA DIRECTA O INDIRECTAMENTE CON NINGUNA ACCIÓN O INACCIÓN QUE USTED TOME EN BASE A LOS MATERIALES.
      </p>
      <p>
      19. Cómo contactarnos
      </p>
      <p>
      Si tiene alguna pregunta o comentario, contáctenos por correo a la siguiente dirección: soporte@reslahacienda.com Tenga en cuenta que las comunicaciones por correo electrónico no serán necesariamente seguras; en consecuencia, no debe incluir información confidencial en su correspondencia de correo electrónico con nosotros.
      </p>
      </Container>
    </PageLayout>
  )
}
export default TermsOfService;